import { msalApiFetch } from "commons/authConfig";
import { API_URL } from "commons/environment";
import { Message } from "types/message/Message";

const endpoint = "messaging";

export function fetchMessages(timesheetId: string): Promise<Message[]> {
  const url = `${API_URL}/${endpoint}/getMessages/${timesheetId}`;
  return msalApiFetch(url, {
    method: "GET",
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}

export function replyUserMessage(note: { timesheetId: string; message: string }): Promise<void> {
  const url = `${API_URL}/${endpoint}/replyUserMessage`;
  return msalApiFetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(note),
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return;
  });
}

export function markMessagesAsRead(messageIds: string[]) {
  const url = `${API_URL}/messaging/markMessagesAsRead`;
  return msalApiFetch(url, {
    method: "PUT",
    body: JSON.stringify(messageIds),
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return;
  });
}

export async function hasUnreadMessages(timesheetId: string): Promise<boolean> {
  const result = await msalApiFetch(`${API_URL}/timesheets/${timesheetId}/has-unread-messages`, {
    method: "GET",
  });

  if (!result.ok) throw result;
  return await result.json();
}
